import React, { Component } from 'react';
import './SectionCarousel.css';

const scrollHandler = count => {  
    return (count * -50) + 50;
};

class SectionCarousel extends Component {
    constructor(props) {
        super();
        this.state = {offset: -50, offsetunits: 'vw', count: 2, moving: false, index: 0, touching:false};
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.slider = {};
    }
  
    componentDidMount(){
        document.addEventListener("keydown", this.handleKeyPress, false);
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleKeyPress, false);
    }

    incrementCount() {
        if(this.state.count < this.props.content.images.length && !this.state.moving) {
            const newState = this.state.count + 1;
            //const oldOffset = scrollHandler(this.state.count);
            //const newOffset = scrollHandler(newState);
/*
            for(let i=oldOffset;i<=newOffset;i+=0.15) {
                this.setState((state) => {
                    return {offset: i};
                });
            }
            */
            this.setState((state) => {
                return {count: newState};
            });

            this.setState((state) => {
                return {offset: scrollHandler(newState),moving: true};
            });

            setTimeout((state) => { 
                this.setState((state) => ({moving: false}))
            }, 1100);
        }
    }

    decrementCount() {
        if(this.state.count !== 1 && !this.state.moving) {
            this.setState((state) => {
                const newState = state.count - 1;
                return {count: newState, offset: scrollHandler(newState),moving: true};
            });
            setTimeout((state) => { 
                this.setState((state) => ({moving: false}))
            }, 1100);
        }
    }

    incrementCountMobile() {

        const newState = this.state.count < this.props.content.images.length ? this.state.count + 1 : this.state.count;

        this.setState((state) => {
            return {count: newState, offset: scrollHandler(newState),offsetunits: 'vw'};
        });
    }
    decrementCountMobile() {
        
        const newState = this.state.count !== 1 ? this.state.count - 1 : this.state.count;
        
        this.setState((state) => {
            return {count: newState, offset: scrollHandler(newState),offsetunits: 'vw'};
        });
    }

    handleKeyPress(event) {

        if(event.keyCode === 39) {
            // right key
            this.incrementCountMobile();

        } else if(event.keyCode === 37) {
            // left key
            this.decrementCountMobile();
        }
    }

    handleScroll(e) {
        if(!this.state.moving) {
            
            if (e.deltaY === 0){ e.preventDefault(); }
            
            if(e.deltaX > 0 && this.state.count < this.props.content.images.length) {
                //scroll right
                this.incrementCount();
            } else if(this.state.count !== 1 && e.deltaX < 0) {
                //scroll left
                this.decrementCount();
            }
        }
    }

    onTouchStart(e) {
        if(e.touches.length === 1) {
            const translateCur = (window.innerWidth / 100) * scrollHandler(this.state.count);
            this.setState((state) => {
                return {offset: translateCur, touching: true, offsetunits: 'px',};
            });
            e.preventDefault(); 
            this.is_touch = (e.touches);
            this.istouching = true;
            this.onTouchMove(e);
            this.offset = e.touches[0].clientX;
            this.currentOffset = e.touches[0].clientX;
            this.translateX = translateCur;
            this.index = 0;
            this.position = 0;
            this.slider.step = 100 / window.innerWidth;
            this.maxStep = window.innerWidth / 2;
            this.innerWidth = window.innerWidth;
            this.maxTranslate = this.props.content.images.length * this.maxStep * -1;
        }
        
    }
    
    onTouchMove(e) {

        const clientX = e.touches[0].clientX;

        if(this.istouching){

            e.preventDefault();

            //let offset = this.offset;
            if (false && this.is_touch) {

                if(e.touches && e.touches[0]){
                    let x;
                    x = (clientX - this.currentOffset) * 0.25;

                    let translateX = this.translateX + x;

                    if (translateX > 0) {
                        this.translateX = 0;
                    } else if (translateX < this.maxTranslate) {
                        this.translateX = this.maxTranslate;
                    } else {
                        this.translateX = translateX;
                    }
                    
                    this.setState((state) => {
                        return {offset: this.translateX};
                    });
                    
                }
            } 
        }
        this.currentOffset = clientX;
        this.position = e.touches[0].clientX;
    }

    onTouchEnd(e) {
        console.log('touchend');
        const delta = this.position - this.offset;
        let count = this.state.count;

        if(delta > 0) {
            // right
            //this.decrementCountMobile();
            if(count !== 1) {
                count--;
            }
            
        } else if (delta < 0) {
            //left
            //this.incrementCountMobile();
            if(this.state.count < this.props.content.images.length) {
                count++;
            }
        }

        this.setState((state) => {
            return {count: count, offset: scrollHandler(count),offsetunits: 'vw'};
        });

        this.istouching = false;
        this.is_touch = false;
        this.offset = 0;
        this.index = 0;
        this.position = 0;
        this.setState((state) => {
            return {touching: false};
        });
    }
    
    render() {

        const objId = this.props.content.id !== "" ? this.props.content.id : this.props.content._id;
        return (
            <section id={objId} className={"project__section project__carousel " + this.props.content.class + (this.istouching ? " touching" : "")} onWheel={this.handleScroll.bind(this)} onTouchStart={this.onTouchStart.bind(this)} onTouchMove={this.onTouchMove.bind(this)} onTouchEnd={this.onTouchEnd.bind(this)}>
                {/*<SectionFlickity content={this.props.content} />*/}
                <div id={"js-content-carousel-" + objId} className="content-carousel__container">
                    <div className="content-carousel__content">
                        <div id={"js-carousel__viewport-" + objId} className="content-carousel__viewport">
                            <div id={"js-carousel__full-" + objId} className={this.state.touching ? "content-carousel__full touching" : "content-carousel__full"} style={{transform: `translateX(${this.state.offset}${this.state.offsetunits})`, WebkitTransform:`translate3d(${this.state.offset}${this.state.offsetunits},0,0)`}}>
                            {   
                                this.props.content.images.map((value, index) => {
                                    return (
                                        <div key={index} id={"content-carousel__slide-" + objId + "-" + index} className="content-carousel__slide">
                                            <img
                                                className="d-block w-100"
                                                src={value.src}
                                                alt={value.title}
                                            />
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SectionCarousel;