import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';

class Error extends Component {
    render() {
        return (
            <main>
                <Container>
                    <h1>404 Error</h1>
                    <p>Page Not found.  Better luck next time.</p>
                </Container>
            </main>
        )
    }
}

export default Error;