import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';

class HomeBanner extends Component {

    constructor() {
        super();
       
        this.state = {
            loaded: false
        }
    }

    componentDidMount() { 
        this.setState({loaded: true});
    }
    render() {
        
        return (
            <div id="home__banner">
                <video autoPlay loop muted width="1920" height="1080" poster="/video/cascade_bkg.jpg">
                {
                    this.state.loaded ?
                    <source src="/video/aerial-beautiful-tall-cascade-splashing-over-rocky-ledge-in-amazing-lush-jungle_bplex8n4__D.mp4" type="video/mp4" />
                    :
                    null
                }
                </video>
                <div id="banner__strapline">
                    <Container className="position-relative">
                        <h1 className="d-none d-sm-block">Helping you to navigate the different streams of digital technology</h1>
                        <div id="dotsRight" className="dots"></div>
                        <div id="dotsLeft" className="dots"></div>     
                        <a className="banner__portfolio d-none d-sm-block" href="/portfolio">Portfolio <img src="/img/ww-icons/circle-arrow-r.svg" alt=">" /></a>                    
                    </Container>
                </div>
            </div>
        );
    }
}

export default HomeBanner;