import React, { Component } from 'react';


class Contact extends Component {
    state = {
        projects: []
    }
    
    render() {
        return (
            <div>
                Contact
            </div>
        );
    }
}

export default Contact;