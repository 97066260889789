import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';

class SectionNumbered extends Component {
    render() {
        return (
            <section id={this.props.content.id !== "" ? this.props.content.id : this.props.content._id} className={"project__section project__numbered " + this.props.content.class}>
                <Container className="container-max-sm">
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <h2><span className="section__number">{this.props.content.number}.</span>{this.props.content.title}</h2>
                        </div>
                        <div className="col-12 col-md-7">
                            <p>{this.props.content.content}</p>
                        </div>
                    </div>
                </Container>
            </section>
        )
    }
}

export default SectionNumbered;