import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import CookieConsent from "react-cookie-consent";

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//import Row from 'react-bootstrap/Row';
//import Col from 'react-bootstrap/Col'
import 'bootstrap/dist/css/bootstrap.min.css';

import Portfolio from './Portfolio/Portfolio';
import Home from './Home/Home';
import Contact from './Contact/Contact';
import Nav from './Nav/Nav';
import Error from './Error/Error';
import HomeBanner from './Home/HomeBanner';

import './App.css';
import './config';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";

const PageWithClass = props => {
  let location = useLocation(), locationClass = "", fullPath = "";
  const q = location.pathname.indexOf("?");

  if(q > 0) {
    fullPath = location.pathname.substring(1,q);
  } else {
    fullPath = location.pathname.substring(1);
  }

  const locationArray = fullPath.split("/");

  if(locationArray[0] === "") {
    locationClass = " page-home";
  } else {
    locationArray.forEach(function(path) {
      locationClass+=" page-" + path;
    });
    locationClass+=" page-" + fullPath;
  }

  return <div className={"App " + locationClass}>{props.children}</div>;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PortfolioRouter(props) {
  let query = useQuery();
  //console.log(query.get("token"));
  return <Portfolio token={query.get("token")}/>;
}

function NavRouter(props) {
  let query = useQuery();
  //console.log(query.get("token"));
  return <Nav token={query.get("token")}/>;
}

const now = new Date()
const getThisYear = now.getFullYear();

class App extends Component {

  componentDidMount() {
    document.title = global.config.site.title + global.config.site.default;
  }

  render() {

    return (
        <Router>
          <PageWithClass>
            <Switch>
              <Route path="/portfolio/:slug">{/* Switch off default header */}</Route>
              <Route>
                <header className="App-header">
                  <Switch>
                      <Route exact path="/">
                        <HomeBanner />
                      </Route>
                  </Switch>
                  <NavRouter  />
                </header>
              </Route>
            </Switch>
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route path="/portfolio">
                    <PortfolioRouter />
                  </Route>
                  <Route path="/contact">
                    <Contact />
                  </Route>
                  <Route>
                    <Error />
                  </Route>
                </Switch>
                <footer>
                  <Container className="container-max-sm">
                        &copy; {getThisYear} whitewater design ltd. All Rights Reserved. Registered company number 12543970. VAT number GB454333795.
                  </Container>
                </footer>
            </PageWithClass>
            <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
         </Router>
    );
  }
}

export default App;
