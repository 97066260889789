import React, { Component } from 'react';
import axios from 'axios';
import { Container } from 'react-bootstrap';

import {
    Switch,
    Link,
    Route,
    useParams, useRouteMatch, NavLink, useLocation
  } from "react-router-dom";

import Error from '../Error/Error';
import Project from './Project/Project';
import Nav from '../Nav/Nav';

function NavRouter(props) {
    let query = useQuery();
    return <Nav token={query.get("token")} />;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
/*
function useQuery() {
    const queryParams = new URLSearchParams(useLocation().search);
    const query =  queryParams.get("token");
    if(query) {
        return "?token=" + query;
    } else {
        return ""
    }
}*/

const api = axios.create({
    baseURL: `https://api.whitewaterdesign.co.uk`
});

const arrayToObject = (array, keyField) => 
    array.reduce((obj,item) => {
        obj[item[keyField]] = item;
        return obj
    }, {});

function MasonryGrid(props) {

    const col1 = [];
    const col2 = [];
    let isCol1 = true;

    for(let i=0;i<props.children.length;i++) {
        if(isCol1) { 
            col1.push(props.children[i]);
            isCol1=false;
        } else {
            col2.push(props.children[i]);
            isCol1=true;
        };
    }
    
    return (
        <div className="row">
            <div className="col-12 col-sm-6">
                {col1}
            </div>
            <div className="col-12 col-sm-6">
                {col2}
            </div>
        </div>
    )
}

function Item(props) {
 
    return (
        <div id={"project" + props.item._id} className="portfolio__project">
            <Link className="portfolio__img" to={"/portfolio/" + props.item.slug + props.token}>
                <img className="img-responsive" src={props.item.feature_img} alt={props.item.title} title={props.item.title} />
            </Link>
        </div>
    )
}
function PortfolioHome(props) {
    let { slug } = useParams();
//    let { path } = useRouteMatch();

    const items = props.projects;

    const token = props.token ? props.token : "";
    
    const technology = props.technologies.find(x => x.slug === props.technology);
    const children = [];

    items.forEach(item => {
        
        if(
            typeof slug === "undefined"
        ) {
            children.push(<Item key={item._id} item={item} token={token} /> );
        } else {
            item.technologies.forEach(tech => {
                if(tech !== null && tech._id.$oid === technology._id) {
                    children.push(<Item key={item._id} item={item} token={token} /> );
                }
            })
        }
    });
    
    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/portfolio" />
                <Route>
                    <header className="App-header">
                        <NavRouter />
                    </header>
                </Route>
            </Switch>
            
            <section id="portfolio">
                <Container className="container-max-sm">
                    <h1>Portfolio</h1>
                    <div className="portfolio__nav clearfix">
                        <ul>
                            <li className="portfolio__categories">Categories:</li>
                            <li id="technologyall">
                                <NavLink 
                                    to={"/portfolio" + token}
                                    isActive={match=>{return match.isExact}}
                                >All</NavLink>
                            </li>
                            {props.technologies.map(item => (
                                item.list ?
                                
                                <li key={item._id} id={"technology" + item._id}>
                                    <NavLink to={"/portfolio/" + item.slug + token}>{item.name}</NavLink>
                                </li>

                                : null
                            ))}
                        </ul>
                    </div>
                    <div className="portfolio__projects clearfix">
                    {
                        children.length > 0 ?
                            <MasonryGrid>{children}</MasonryGrid>                    
                        :

                        null
                    }   
                    </div>
                </Container>
            </section>
        </React.Fragment>
    )
}
function ProjectRouter(props) {
//  let { path } = useRouteMatch();
    let { slug } = useParams();
    //console.log(props.technologies);
    
    const technologyids = arrayToObject(props.technologies,'slug');

    if(
        props.technologies.length > 0
    ) {
        if(props.projectids[slug]) {
            return <Project data={props.projectids[slug]} projectids={props.projectids} tecnologies={technologyids} projects={props.projects} />;
        } else if (technologyids[slug]) {
            return <PortfolioHome projects={props.projects} technology={slug} technologies={props.technologies} token={props.token} />;
        } else {
            return <Error />;
        }
    }  else {
        return <div />;
    }
}

function CheckSlug (props) {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/:slug`}>
                <ProjectRouter projectids={props.projectids} projects={props.projects} technologies={props.technologies} token={props.token} />                
            </Route>
            <Route exact path={path}>
                <PortfolioHome projects={props.projects} token={props.token} />
            </Route>
            <Route>
                <Error />
            </Route>
        </Switch>
    )
}
    
class Portfolio extends Component {
   
    constructor() {
        super();
       
        this.state = {
            projects: [],
            projectids: {},
            technologies: [],
            technologyids: {},
        }
    }

    signal = axios.CancelToken.source();

    componentDidMount() {    
        document.title = global.config.site.title + `Portfolio`;  
        let token = this.props.token != null ? "?token=" + this.props.token : "";

        //const CancelToken = axios.CancelToken;
        //const source = CancelToken.source();

        //console.log('mounting');
        api.get('/portfolio' + token).then(res => {
            this.setState({ projects: res.data });
            const projectids = arrayToObject(res.data,'slug');
            this.setState({ projectids: projectids});
        }).catch(function (thrown) {
            if (axios.isCancel(thrown)) {
                console.log('Request canceled', thrown.message);
            } else {
                // handle error
            }
        });

        api.get('/tech').then(res => {
            const data = res.data;
            this.setState({ technologies: data });
            const technologyids = arrayToObject(res.data,'slug');
            this.setState({ technologyids: technologyids});
        }).catch(function (thrown) {
            if (axios.isCancel(thrown)) {
                console.log('Request canceled', thrown.message);
            } else {
                // handle error
            }
        });
    }
    
    componentWillUnmount() {
        this.signal.cancel('Api is being canceled');
    }

    render() {
        
        let token = this.props.token != null ? "?token=" + this.props.token : "";
        
        return (
            
                <Switch>
                    <Route exact path="/portfolio">
                        <main>
                            <PortfolioHome projects={this.state.projects} token={token} technologies={this.state.technologies} />
                        </main>
                    </Route>
                    {
                        Object.keys(this.state.projectids).length > 0 
                        
                        ?
                            <Route 
                                path="/portfolio"
                                render={(props) => (
                                    <CheckSlug {...props} projectids={this.state.projectids} projects={this.state.projects} technologies={this.state.technologies} token={token} />
                                )}
                            />
                        :
                        
                        null
                    }
                    
                </Switch>
                
           
        );
    }
}

export default Portfolio;