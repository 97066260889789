import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import './Home.css';

const frontEnd = ['Next.js/React', 'Vue.js/Nuxt', 'React Testing Library', 'TypeScript', 'ES6', 'GraphQL', 'HTML5/CSS3', 'Dato Headless CMS']
const backEnd = ['node.js/Typescript', 'Fastify/Express.js', 'Jest', 'Test Driven Development', 'Domain Driven Design', 'Postgres/MySQL', 'Java', 'Laravel/PHP', 'ML/Artificial Intelligence/Python', 'MongoDB/noSQL']
const devops = ['Event Driven Microservice Architecture', 'CI/CD', 'CucumberJS', 'Terraform', 'AWS ECS/Lamdas', 'ECS', 'Docker', 'Kubernetes', 'Wordpress']

class Home extends Component {
    state = {
        projects: []
    }

    /*
    constructor() {
        super();

    }*/

    componentDidMount() {
        document.title = global.config.site.title + global.config.site.default;
    }

    render() {

        return (

            <main>

                <Container>
                    <h1 className="d-block d-sm-none home-banner">Helping you to navigate the different streams of
                        digital technology</h1>
                    <a className="banner__portfolio d-block d-sm-none" href="/portfolio">Portfolio <img
                        src="/img/ww-icons/circle-arrow-r-blk.svg" alt=">"/></a>
                </Container>

                <section className="section__titleleft">
                    <Container className="container-max-sm">
                        <div className="row">
                            <div className="col-12 col-md-2">
                                <h2>The Story</h2>
                            </div>
                            <div className="col-12 col-md-10">
                                <h3>We have over a decade of experience in Software Engineering;
                                    passionate about event driven micro service architecture, domain driven design
                                    and building and managing solid & well-engineered team codebases.</h3>
                                <p>With an appreciation for beautiful design, test-driven development approach
                                    and a love of technology, we can perfectly combine the different streams of digital
                                    technology to deliver a user friendly end product that will inspire for years.
                                    We are also well versed in many of the new technologies that are saturating the industry.
                                    See below for a list of technologies we use.</p>
                            </div>
                        </div>
                    </Container>
                </section>
                <section className="section__titleleft section__blue">
                    <Container className="container-max-sm">
                        <div className="row">
                            <div className="col-12 col-md-2">
                                <h2>What we do</h2>
                            </div>
                            <div className="col-12 col-md-10">
                                <h3>Let’s get down to the nuts and bolts, we are Fullstack Developers and Digital
                                    Designers. Here is a full overview of the technology stack and knowledge currently
                                    in use in our live projects.</h3>
                                <div className="row home__technologies">
                                    <div className="col-12 col-sm-4">
                                        <h4>Front End</h4>
                                        <ul className="SourceSans">
                                            {
                                                frontEnd.map(fe => (
                                                    <li>{fe}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <h4>Back End</h4>
                                        <ul className="SourceSans">
                                            {
                                                backEnd.map(fe => (
                                                    <li>{fe}</li>
                                                ))
                                            }
                                            {/*<li>node.js / Typescript</li>*/}
                                            {/*<li>PHP</li>*/}
                                            {/*<li>MySQL</li>*/}
                                            {/*<li>MongoDB</li>*/}
                                            {/*<li>Express</li>*/}
                                            {/*<li>Laravel</li>*/}
                                            {/*<li>Python / Artificial Intelligence</li>*/}
                                            {/*<li>SITEFORUM Script</li>*/}
                                        </ul>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <h4>DevOps/Sys Admin</h4>
                                        <ul className="SourceSans">
                                            {
                                                devops.map(fe => (
                                                    <li>{fe}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <a href="/portfolio" className="btn btn-circular btn-arrow-right">
                                    View Portfolio <FontAwesomeIcon icon={faChevronRight}/>
                                </a>
                            </div>
                        </div>
                    </Container>
                </section>
                <section className="section__titleleft section__creators">
                    <Container className="container-max-sm">
                        <div className="row">
                            <div className="col-12 col-md-2">
                                <h2>Our Team</h2>
                            </div>
                            <div className="col-12 col-md-10">
                                <h3>A multi-disciplinary team of creators</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-4">
                                <figure>
                                    <img className="img-responsive" src="/img/home/creator-chris.jpg"
                                         alt="Chris Cole | CEO / Senior Fullstack Software Engineer"/>
                                    <figcaption>
                                        <h4>Chris Cole</h4>
                                        <p>CEO / Senior Fullstack Developer</p>
                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col-12 col-sm-4">
                                <figure>
                                    <img className="img-responsive" src="/img/home/creator-mark.jpg"
                                         alt="Mark Wójcicki | Senior Design Consultant"/>
                                    <figcaption>
                                        <h4>Mark Wójcicki</h4>
                                        <p>Senior Design Consultant</p>
                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col-12 col-sm-4">
                                <figure>
                                    <img className="img-responsive" src="/img/home/creator-aleksandra.jpg"
                                         alt="Aleksandra Cole | Quality Assurance Manager / Book Keeper"/>
                                    <figcaption>
                                        <h4>Aleksandra Cole</h4>
                                        <p>Project Manager and Testing</p>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </Container>
                </section>
                <section id="contact">
                    <Container className="container-max-sm">
                        <div className="row">
                            <div className="col-12 col-md-6  contact__left">
                                <h2>Contact Us</h2>
                            </div>
                            <div className="col-12 col-md-6 contact__right">
                                <div className="contact__detail">
                                    <h4>OFFICE</h4>
                                    <p>18 Ramleaze Drive<br/>
                                        Shaw, Swindon SN5 5QA</p>
                                </div>
                                <div className="contact__detail">
                                    <h4>GET IN TOUCH</h4>
                                    <p><a
                                        href="mailto:sales@whitewaterdesign.co.uk">sales@whitewaterdesign.co.uk</a><br/>
                                        <a href="tel:+447895012630">07895012630</a></p>
                                </div>
                                <div>
                                    <div className="contact__detail">
                                        <h4>FOLLOW US</h4>
                                        <p>Connect with us on <a
                                            href="https://www.linkedin.com/company/whitewater-design-ltd"
                                            target="_blank" rel="noopener noreferrer">linkedin</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
        );
    }
}

export default Home;
