import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
//import Portfolio from '../Portfolio';
import Nav from '../../Nav/Nav';
import {
    Link,useLocation
  } from "react-router-dom";
import SVG from 'react-inlinesvg';
import ProjectSection from './ProjectSection';
import ScrollToTop from '../../Utils/ScrollToTop';


function NavRouter(props) {
    let query = useQuery();
    return <Nav token={query.get("token")} projecttype={props.projecttype} />;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function convertUnicode(input) {
    return input.replace(/\\u(\w\w\w\w)/g,function(a,b) {
      var charcode = parseInt(b,16);
      return String.fromCharCode(charcode);
    });
  }

function ProjectTechnologies(props) {
    const techno = [];
    props.children.forEach(tech => {
       techno.push(<div id={'tech_'+ tech._id.$oid} key={tech._id.$oid} className="project-head__svg" title={tech.name}><SVG src={'/img/icons/' + tech.src} style={{fill:tech.color}} /></div>);
    });

    return <div className="project-head__tech">{techno}</div>;
}


class Project extends Component {
    constructor() {
        super();
       
        this.state = {
            route: ""
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        const p = this.props.data._id;
        const pindex = this.props.projects.findIndex(i => i._id === p);
        const nextProject = pindex+1 < this.props.projects.length ? this.props.projects[pindex+1] : this.props.projects[0];
        const prevProject = pindex > 0 ? this.props.projects[pindex-1] : this.props.projects[this.props.projects.length-1];

        return(
            <div className={"page-project page-project-" + this.props.data.type || "default"}>
                <header className="App-header" style={{backgroundImage: `url(/img/portfolio/header/${this.props.data._id}@2x.jpg)`}}>
                    <NavRouter  projecttype={this.props.data.type || "default"} />
                    <ScrollToTop />
                    <div className="project-head__central">
                        <Container className="container-max-sm">
                            <div className="project-head__title">
                                <h1>
                                    <small>{this.props.data.strap}</small>
                                    {this.props.data.title}
                                </h1>
                                <p className="project-head__short">
                                    {convertUnicode(this.props.data.short_desc)}
                                </p>
                            </div>
                        </Container>
                    </div>
                    <div className="project-head__details">
                        <Container className="container-max-sm">
                            <div className="row">
                                <div className="col-12 col-lg-2 mb-3">
                                    <h4>CLIENT</h4>
                                    {this.props.data.client}
                                </div>
                                <div className="col-12 col-lg-1 mb-3">
                                    <h4>YEAR</h4>
                                    {this.props.data.year}
                                </div>
                                <div className="col-12 col-lg-7 mb-2">
                                    <h4>TECHNOLOGIES</h4>
                                    <ProjectTechnologies tech_list={this.props.tecnologies}>{this.props.data.technologies}</ProjectTechnologies>
                                </div>
                                {
                                    this.props.data.link !== "" ?

                                    <div className="col-12 col-lg-2 mb-3">
                                        <h4>&nbsp;</h4>
                                        <a className="circle-link" href={this.props.data.link} rel="noopener noreferrer" target="_blank">See Website 
                                        {
                                            this.props.data.type === "header" ?
                                            <img src="/img/ww-icons/circle-arrow-r.svg" alt=">" /> :
                                            <img src="/img/ww-icons/circle-arrow-r-blk.svg" alt=">" />
                                        }
                                        </a>
                                    </div> : null
                                }
                                
                            </div>
                       </Container>
                    </div>
                </header>
                <ProjectSection>
                    {this.props.data.sections}
                </ProjectSection>
                <section className="project__footer">
                    {/*<div className="text-center">
                        <h5>Next Project</h5>
                        {false ? null : <h4>{nextProject.title}</h4>}
                        <Link className="circle-link" to={"/portfolio/" + nextProject.slug}><img src="/img/ww-icons/circle-arrow-r-blk.svg" alt=">" /></Link>;
                            </div>*/}
                    <Container>
                        <div className="row">
                            <div className="col-12 col-sm text-center d-block d-sm-none mb-5">
                                <Link className="circle-link" to="/portfolio/">
                                    
                                    <div className="mb-3">Back to Portfolio</div>
                                    <img style={{transform:'rotate(-90deg)'}} src="/img/ww-icons/circle-arrow-r-blk.svg" alt=">" /> 
                                </Link>
                            </div>
                            <div className="col-12 col-sm  mb-4">
                                <div className="footer-wrap">
                                    <Link className="circle-link circle-link-left" to={"/portfolio/" + prevProject.slug} title={prevProject.title}>
                                        <img src="/img/ww-icons/circle-arrow-r-blk.svg" alt=">" />
                                        <div>Previous Project<br />{prevProject.title}</div>
                                    </Link>   
                                </div>
                            </div>
                            <div className="col-12 col-sm text-center d-none d-sm-block mb-4">
                                <Link className="circle-link" to="/portfolio/">
                                    
                                    <div className="mb-3">Back to Portfolio</div>
                                    <img style={{transform:'rotate(-90deg)'}} src="/img/ww-icons/circle-arrow-r-blk.svg" alt=">" /> 
                                </Link>
                            </div>
                            <div className="col-12 col-sm">
                                <div className="footer-wrap text-right  mb-4">
                                    <Link className="circle-link justify-content-end" to={"/portfolio/" + nextProject.slug} title={nextProject.title}>
                                        <div>Next Project<br />{nextProject.title}</div>
                                        <img  src="/img/ww-icons/circle-arrow-r-blk.svg" alt=">" />    
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
        );
    }
}

export default Project;