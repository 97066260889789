import React, { Component } from 'react';

class SectionImageCover extends Component {
    render() {
        return (
            <section id={this.props.content.id !== "" ? this.props.content.id : this.props.content._id} className={"project__section project__image project__imagecover" + this.props.content.class}>
                <img className={this.props.type === "shadow" ? "project__img project_imgshadow full-width" : "project__img full-width"} alt={this.props.content.alt} title={this.props.content.alt} src={this.props.content.src} />
            </section>
        )
    }
}

export default SectionImageCover;