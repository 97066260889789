import React, { Component } from 'react';
import SectionCarousel from './Sections/SectionCarousel';
import SectionImage from './Sections/SectionImage';
import SectionNumbered from './Sections/SectionNumbered';
import SectionResponsive from './Sections/SectionResponsive';
import SectionImageCover from './Sections/SectionImageCover';
import SectionText from './Sections/SectionText';
import './Sections/Sections.css';

function ProjectSectionType(props) {
    
    const sections = [];
    props.children.forEach((child,token) => {
        switch(child.type) {
            case "numbered_text":
                sections.push(<SectionNumbered content={child} key={token} />);
                break;
            case "text_vertical":
                sections.push(<SectionText content={child} key={token} />);
                break;
            case "carousel":
                sections.push(<SectionCarousel content={child} key={token} />);
                break;
            case "img":
                sections.push(<SectionImage content={child} key={token} />);
                break;
            case "img_shadow":
                sections.push(<SectionImage content={child} type="shadow" key={token} />);
                break;
            case "img_container":
                child.class += " nopadding";
                sections.push(<SectionImage content={child} type="container" key={token} />);
                break;
            case "img_cover":
                sections.push(<SectionImageCover content={child} key={token} />);
                break;
            case "responsive_left":
                sections.push(<SectionResponsive type="left" content={child} key={token} />);
                break;
            case "responsive_right":
                sections.push(<SectionResponsive type="right" content={child} key={token} />);
                break;
            default:
                sections.push(<div />);
        }
    });

    return <main>{sections}</main>;
}

class ProjectSection extends Component {
    render() {
        return (
            <ProjectSectionType>{this.props.children}</ProjectSectionType>
        )
    }
}

export default ProjectSection;