import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';

class SectionText extends Component {
    render() {
        return (
            <section id={this.props.content.id !== "" ? this.props.content.id : this.props.content._id} className={"project__section project__text " + this.props.content.class}>
                <Container className="container-max-sm">
                    <div className="row">
                        <div className="col-12 col-md-2">
                            {
                                typeof this.props.content.number !== "undefined" ?
                                
                                <small className="section__number">{this.props.content.number} &ndash;</small> :

                                null
                            }
                            
                        </div>
                        <div className="col-12 col-md-9">
                            <h2>{this.props.content.title}</h2>
                            <p>{this.props.content.content}</p>
                            {
                                this.props.content.link ?

                                <div className="float-right mt-3">
                                    <a class="circle-link" href={this.props.content.link} target="_blank" rel="noopener noreferrer">{this.props.content.link_title || "See website"}<img src="/img/ww-icons/circle-arrow-r-blk.svg" alt=">" /></a>
                                </div>

                                : null
                            }
                            
                        </div>
                    </div>
                </Container>
            </section>
        )
    }
}

export default SectionText;