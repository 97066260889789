import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes  } from '@fortawesome/free-solid-svg-icons';
import logoWhite from '../assets/img/svg/logo_white.svg';
import logoBlack from '../assets/img/svg/logo_bk.svg';

import {
    Switch,
    Route,
    Link
  } from "react-router-dom";

class MainNav extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
          showMenu: false,
          route: ""
        };
        
        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
      }
      
      showMenu(event) {
        event.preventDefault();
        
        this.setState({ showMenu: true }, () => {
          document.addEventListener('click', this.closeMenu);
        });
      }
      
      closeMenu() {
        this.setState({ showMenu: false }, () => {
          document.removeEventListener('click', this.closeMenu);
        });
      }
    
      render() {

        let token = this.props.token != null ? "?token=" + this.props.token : "";
        if(typeof this.props.projecttype !== "undefined") {

        }
        return (
            <div>
                <div id="topNav">
                    <Container>
                        <button id="nav_menu" onClick={this.showMenu}>
                                <Route render={routeProps => (
                                    <FontAwesomeIcon icon={this.state.showMenu ? faTimes : faBars} color={this.state.showMenu || routeProps.location.pathname === "/" ? "white" : "black"}/>
                                )} />
                        </button>
                        <a href="/">
                            <Switch>
                                <Route exact path="/">
                                    <img src={logoWhite} className="App-logo" alt="logo" />
                                </Route>
                                <Route exact path={"/portfolio/studio-stanley" || "nopath"}>
                                    <img src={logoWhite} className="App-logo" alt="logo" />
                                </Route>
                                <Route>
                                    <img src={this.state.showMenu || (typeof this.props.projecttype !== "undefined" && this.props.projecttype !== null && (this.props.projecttype === "header")) ? logoWhite : logoBlack} className="App-logo" alt="logo" />
                                </Route>
                            </Switch>
                        </a>
                    </Container>
                </div>
                {
                    this.state.showMenu
                    ? (
                        <nav>
                            <Container>
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link to={"/portfolio" + token}>Portfolio</Link>
                                    </li>
                                    <li>
                                        <a href="https://hosting.whitewaterdesign.co.uk/" rel="noopener noreferrer" target="_blank">Managed Hosting</a>
                                    </li>
                                    <li>
                                        <a href="/#contact" rel="noopener noreferrer">Contact Us</a>
                                    </li>
                                </ul>
                            </Container>
                        </nav>
                    )
                    : (
                        null
                    )
                }
            </div>
        );
      }
}


export default MainNav;