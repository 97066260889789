import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';

class SectionImage extends Component {
    render() {
        return (
            <section id={this.props.content.id !== "" ? this.props.content.id : this.props.content._id} className={"project__section project__image " + this.props.content.class}>
                <Container className={this.props.type === "container" ? "" : "container-max-sm"}>
                    {
                        typeof this.props.content.title === "undefined" || this.props.content.title === "" ? null :
                        <div className="project__numbered">
                            <div className="row">
                                <div className="col-12 col-md-5">
                                    <h2><span className="section__number">{this.props.content.number}.</span>{this.props.content.title}</h2>
                                </div>
                                <div className="col-12 col-md-7">
                                    <p>{this.props.content.content}</p>
                                </div>
                            </div>
                        </div>
                    }
                    
                    <img className={this.props.type === "shadow" ? "project__img project_imgshadow" : "project__img"} alt={this.props.content.alt} title={this.props.content.alt} src={this.props.content.src} />
                </Container>
            </section>
        )
    }
}

export default SectionImage;