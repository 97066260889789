import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';


function ResponsiveText(props) {

    return (
        <div className={props.type === 'left' ? "col-12 offset-md-1 col-md-7" : "col-12 col-md-8"}>
            <div className="section__text">
                <div className="row">
                    <div className="col-12 col-md-1">
                        <span className="section__txtno">{props.content.number}</span>&ndash;
                    </div>
                    <div className="col-12 col-md-11 pl-md-5 pl-38">
                        <h2>{props.content.title}</h2>
                        <p>{props.content.content}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ResponsiveImage(props) {

    return (
        <div className="col-12 col-md-4">
            <div className="section__mobile">
                <img src={props.content.src} alt={props.content.alt} title={props.content.alt} />
                <div className="section__mobileno">0{props.content.number}</div>
            </div>
        </div>
    )
}

class SectionResponsive extends Component {
    render() {
        return (
            <section id={this.props.content.id !== "" ? this.props.content.id : this.props.content._id} className={"project__section project__responsive project__responsive" + this.props.content.type + " " + this.props.content.class}>
                <Container className="container-max-sm">
                    {
                        this.props.type === "left" ?
                        
                        <div className="row">
                            <ResponsiveImage content={this.props.content} type="left" />
                            <ResponsiveText content={this.props.content} type="left"/>
                        </div>

                        :

                        <div className="row">
                            <ResponsiveText content={this.props.content} type="right" />
                            <ResponsiveImage content={this.props.content} type="right" />
                        </div>
                    }                    
                </Container>
            </section>
        )
    }
}

export default SectionResponsive;